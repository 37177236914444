.h-fit-content {
    height: fit-content;
}

.w-fit-content {
    width: fit-content;
}

button:focus {
    outline: none;
}

input:focus {
    outline: none;
}

textarea:focus {
    outline: none;
}

select:focus {
    outline: none;
}

.rounded-tr-6xl {
    border-top-right-radius: 3rem;
}

.sidebar-shadow {
    box-shadow: 4px 0px 15px 2px black;
}

.header-shadow {
    box-shadow: 0px -5px 8px 5px black;
}

input {
    min-height: 44.18px !important;
}

select {
    min-height: 44.18px !important;
}

button {
    min-height: 44.18px !important;
}

.fancy-scroll::-webkit-scrollbar {
    width: 0;
}

.z-1 {
    z-index: 1;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: rgba(var(--primary-dark), 1);
}

input:focus + .slider {
    box-shadow: 0 0 1px var(--primary-dark);
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.ql-toolbar svg {
    width: 100%;
}

.cover-image {
    background-image: url('../../img/Wallpaper1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-attachment: fixed;
    height: 100%;
}

.rotate-30 {
    --tw-rotate: 30deg;
}

.-rotate-30 {
    --tw-rotate: -30deg;
}

.blur-xl {
    filter: blur(24px);
}

.blur-2xl {
    filter: blur(40px)
}

.pagination {
    .MuiPagination-ul {
        button {
            width: 42px !important;
            height: 42px !important;
            border-radius: 50% !important;
        }
    }
}

.desc-html {
    a {
        color: blue;
        text-decoration: underline;
    }
}

.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}

.min-h-min {
    min-height: min-content !important;
}

.min-h-fit {
    min-height: fit-content !important;
}

@media (min-width: 768px) {
    .md\:h-80vh {
        height: 80vh
    }
    
    .md\:h-40vh {
        height: 40vh
    }
}