:root {
    --primary: 237, 116, 223;
    --primary-dark: 237, 116, 223;
    --primary-light: 237, 116, 223;
    --secondary: 237, 116, 223;
}

.text-primary {
    color: rgba(var(--primary), 1);
}

.border-primary {
    border-color: rgba(var(--primary), 1);
}

.bg-primary {
    background-color: rgba(var(--primary), 1)
}

.bg-secondary {
    background-color: rgba(var(--secondary), 1)
}

.hover\:bg-primary:hover {
    background-color: rgba(var(--primary), 1);
}

.text-primary-dark {
    color: rgba(var(--primary-dark), 1);
}

.border-primary-dark {
    border-color: rgba(var(--primary-dark), 1);
}

.bg-primary-dark {
    background-color: rgba(var(--primary-dark), 1);
}

.hover\:bg-primary-dark:hover {
    background-color: rgba(var(--primary-dark), 1);
}

.bg-primary-light {
    background-color: rgba(var(--primary-light), 1);
}

.custom-box-shadow-gray-200 {
    box-shadow: 0 0 transparent, 0 0 transparent, 0px 0px 32px 10px rgb(217, 217, 217);
}

.hover\:border-primary:hover {
    border-color: rgba(var(--primary), 1)
}

.bg-black-transparent-0\.5 {
    background: rgba(0, 0, 0, 0.5)
}