.ball {
    animation: bounce 1s infinite alternate;
    -webkit-animation: bounce 500ms infinite alternate;
}
@keyframes bounce {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(-45px);
    }
}
@-webkit-keyframes bounce {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(-45px);
    }
}


.slide-transition-down-primary-0\.8 {
    top: -100%;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(var(--primary), 0.8);
    transition: all 500ms ease;
    transform: translateY(100%);
    z-index: 1;
}

.slide-transition-down-black-0\.4 {
    top: -100%;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    transition: all 500ms ease;
    transform: translateY(100%);
    z-index: 1;
}

.slide-transition-up {
    top: 100%;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    transition: all 700ms ease;
    transform: translateY(-100%);
    z-index: 1;
}

.rotate-90 {
    transform: rotate(90deg);
}