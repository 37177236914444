@font-face {
    font-family: 'SF Pro Bold';
    src: local('SF Pro Bold'), url(../fonts/SFProBold.OTF) format('truetype');
}

@font-face {
    font-family: 'SF Pro Medium';
    src: local('SF Pro Medium'), url(../fonts/SFProMedium.OTF) format('truetype');
}

@font-face {
    font-family: 'SF Pro Light Italic';
    src: local('SF Pro Light Italic'), url(../fonts/SFProLightItalic.OTF) format('truetype');
}

@font-face {
    font-family: 'SF Pro Regular';
    src: local('SF Pro Regular'), url(../fonts/SFProRegular.OTF) format('truetype');
}

h1, h2, h3, h4, h5, h6 {
    font-family: SF Pro Bold;
}

span, p, small, li {
    font-family: SF Pro Medium;
}

.light-italic {
    font-family: SF Pro Light Italic;
}

.font-regular {
    font-family: SF Pro Regular !important;
}

button {
    font-family: SF Pro Bold;
}